import {ICalloutProps} from '@fluentui/react';

import React, {useMemo, useCallback} from 'react';

import {IFormFieldsOfSimulationsPage} from 'features/Simulations/SimulationsPage/SimulationsPage.types';
import {
	ControlledTagPicker,
	ResolveSuggestionsFromBaseOnes,
} from 'components/hookForms';
import {
	IVehicleProjectSuggestion,
	SuggestionWithGroupInfo,
} from 'features/Simulations/SimulationsPage/FormFieldsOfSimulationsPage/VehicleProjectsReferencePicker/VehicleProjectsReferencePicker.types';
import _ from 'lodash';

import {CustomTagPicker} from 'features/Simulations/SimulationsPage/FormFieldsOfSimulationsPage/VehicleProjectsReferencePicker/CustomPicker';

export const VEHICLE_PROJECT_TYPE = 'vehicleProjects';
export const VehicleProjectsForFAQ: React.FC<any> = ({
	control,
	t,
	vehicleProjects,
}) => {
	const getTagName = useCallback((project: any): any['generation'] => {
		return project.generation;
	}, []);

	const resolveSuggestionsFromBaseOnes: ResolveSuggestionsFromBaseOnes<any> =
		useCallback((suggestions): SuggestionWithGroupInfo[] => {
			const getIfSuggestionIs1stItemInItsGroup = (
				suggestion: IVehicleProjectSuggestion,
				index: number,
				suggestions: IVehicleProjectSuggestion[],
			): boolean => {
				if (index === 0) return true;
				const previousSuggestion: IVehicleProjectSuggestion =
					suggestions[index - 1];
				return suggestion.modelSeries !== previousSuggestion.modelSeries;
			};

			const createSuggestion = (
				suggestion: IVehicleProjectSuggestion,
				index: number,
				suggestions: IVehicleProjectSuggestion[],
			): SuggestionWithGroupInfo => {
				const isNewGroup: boolean = getIfSuggestionIs1stItemInItsGroup(
					suggestion,
					index,
					suggestions,
				);
				return {...suggestion, isNewGroup};
			};

			return suggestions.map(createSuggestion);
		}, []);

	const pickerCalloutProps = useMemo((): ICalloutProps => {
		return {
			calloutMaxWidth: 600,
			calloutMaxHeight: 300,
			calloutMinWidth: 260,
		};
	}, []);
	return (
		<ControlledTagPicker
			name={VEHICLE_PROJECT_TYPE}
			control={control}
			getName={getTagName}
			selectableItems={vehicleProjects}
			label={`${t(`VehicleProject`)} *`}
			hideTagIcon
			TagPickerComponent={CustomTagPicker}
			pickerCalloutProps={pickerCalloutProps}
			resolveSuggestionsFromBaseOnes={resolveSuggestionsFromBaseOnes}
			placeholder=''
		/>
	);
};
