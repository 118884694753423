import {PanelType} from '@fluentui/react';
import {useUserContext} from 'authentication/UserContext';
import {EntityListColumn, EntityListProps} from 'components';
import {EntityContextProvider} from 'components/EntityPage/EntityContext';
import {EntityPage} from 'components/EntityPage/EntityPage';
import {LoadWrapper} from 'components/LoadWrapper';
import {useGetConvolutesQuery} from 'features/AdminSection/hooks/convolutes.generated';
import {useGetGdprRuleQuery} from 'features/AdminSection/hooks/gdprRules.generated';
import {ParagraphsContextProvider} from 'features/RegulatoryDocuments/components/DocumentDetails/ParagraphsContext';
import {useCreateRequirementMutation} from 'features/RegulatoryDocuments/hooks/useCreateRequirement.generated';
import React, {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {ConvoluteType, Requirement, RequirementStatus} from 'types';
import {NewRequirementVersionPanel} from './components';
import {
	GetAllRequirementsDocument,
	useDeleteRequirementMutation,
	useGetAllRequirementsQuery,
} from './hooks/requirements.generated';
import {useRequirementColumns} from './hooks/useRequirementColumns';
import {RequirementsTooltipTranslationProvider} from './RequirementsTooltipTranslationProvider';
import {RequirementUtilsService} from './RequirementUtils.service';
import {
	UpdateRequirement,
	UpdateRequirementAttachments,
	useRequirementUpdater,
	useRequirementsFormElementsRenderer,
	useRequirementAttachmentsUpdater,
} from './hooks/requirementFormHooks';
import {RequirementFromListPage} from './requirementPage.types';
import {useRequirementsTranslation} from './hooks/useRequirementsTranslation';
import {getRenderRowWithContextualMenu} from 'components/EntityList/RowWithContextualMenu/RowWithContextualMenu';
import {createHrefFromRequirement} from './requirementLinks.utils';
import {useCloneRequirement} from './hooks/useCloneRequirement';
import {useRequirementsVersions} from './hooks/useRequirementsVersions';

import {isDevEnvironment, isTestEnvironment} from 'appShell/Layout/Navigation';
import {FaqCommand} from 'components/Faq/FaqCommand';
import {useQuestionCountsByEntityTypeQuery} from 'components/Faq/Faq.generated';
import {RequirementsCollectionName} from 'components/Faq/EntityTypeMapper';

const {createCommonCreationInputFields} = new RequirementUtilsService();

const RequirementsPage: React.FC = () => {
	const {t} = useRequirementsTranslation();

	const {loading, data} = useGetAllRequirementsQuery();

	//todo: consider adding loading icon on FAQ column during questions loading
	const {data: questions} = useQuestionCountsByEntityTypeQuery({
		variables: {entityType: RequirementsCollectionName},
	});

	const [createRequirementMutation] = useCreateRequirementMutation();
	const [deleteRequirementMutation] = useDeleteRequirementMutation();
	const {cloneRequirement} = useCloneRequirement();
	const {hasEditableChildren} = useRequirementsVersions();

	const requirements = React.useMemo(
		() =>
			data?.requirements?.map((elem, i) => {
				if (!(isDevEnvironment() || isTestEnvironment())) {
					return elem;
				}

				const question = questions?.questionCountsByEntityType.find(
					(q: any) => {
						return q.entityId === elem.id;
					},
				);

				return {...elem, question};
			}) ?? [],
		[data, questions],
	);

	const sortedRequirementsByName: RequirementFromListPage[] = [...requirements]
		.filter(
			req =>
				req.status !== RequirementStatus.Interim &&
				req.status !== RequirementStatus.InterimCompleted,
		)
		.sort((a, b) => a.modifiedAt.localeCompare(b.modifiedAt.toLowerCase()))
		.concat(
			[...requirements]
				.filter(
					req =>
						req.status === RequirementStatus.Interim ||
						req.status === RequirementStatus.InterimCompleted,
				)
				.sort((a, b) => a.modifiedAt.localeCompare(b.modifiedAt.toLowerCase())),
		);

	function sortBySuccessor(
		arr: RequirementFromListPage[],
	): RequirementFromListPage[] {
		const sortedArray = [];

		for (const obj of arr) {
			const successorIndex = obj.successor
				? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				  arr.findIndex(item => item.id === obj.successor!.id)
				: -1;

			if (successorIndex === -1) {
				sortedArray.push(obj);
			} else {
				sortedArray.splice(successorIndex, 0, obj);
			}
		}

		return sortedArray;
	}

	const navigate = useNavigate();

	const onViewRequirement = React.useCallback(
		(req: RequirementFromListPage) => {
			const href: string = createHrefFromRequirement(req);
			return navigate(href);
		},
		[navigate],
	);

	const hasCorrectRightsToEdit = (selectedItem: RequirementFromListPage) => {
		const vexClusterMatch = selectedItem.vexClusters.some(vc =>
			myVexClusters.some(mvc => mvc.id === vc.id),
		);

		return !isAdmin && !vexClusterMatch;
	};

	const refetchQueries = React.useMemo(() => [GetAllRequirementsDocument], []);

	const {isAdmin, isVex, myVexClusters} = useUserContext();

	const disableDelete = React.useCallback(
		(selectedItem: RequirementFromListPage) => {
			const requirementStatus = selectedItem.status;

			return (
				hasCorrectRightsToEdit(selectedItem) ||
				requirementStatus === RequirementStatus.Approval ||
				requirementStatus === RequirementStatus.Final
			);
		},
		[isAdmin, myVexClusters],
	);

	const disableEdit = React.useCallback(
		(selectedItem: RequirementFromListPage) => {
			return (
				hasEditableChildren(selectedItem) ||
				hasCorrectRightsToEdit(selectedItem) ||
				selectedItem.status === RequirementStatus.Approval
			);
		},
		[isAdmin, myVexClusters],
	);

	const {data: convolutes} = useGetConvolutesQuery();
	const convolute = React.useMemo(
		() =>
			convolutes?.convolutes?.find(
				c => c.convoluteType === ConvoluteType.Requirement,
			),
		[convolutes],
	);

	const {data: gdprRule} = useGetGdprRuleQuery({
		variables: {
			id: convolute?.gdprRule?.id ?? '',
		},
	});

	const rule = React.useMemo(() => gdprRule?.gdprRule, [gdprRule]);

	const createRequirement = React.useCallback(
		async (requirement: RequirementFromListPage) => {
			if (requirement.status === RequirementStatus.Final) {
				const origComparableRequirement = sortedRequirementsByName.find(
					req => req.id === requirement.id,
				);

				const res = await cloneRequirement(
					requirement as Requirement,
					refetchQueries,
					rule,
					origComparableRequirement,
				);
				return res?.data?.createRequirement?.requirement;
			}

			const res = await createRequirementMutation({
				variables: {
					input: createCommonCreationInputFields(requirement, rule),
				},
				refetchQueries,
			});
			return res.data?.createRequirement?.requirement;
		},
		[
			cloneRequirement,
			createRequirementMutation,
			refetchQueries,
			rule,
			sortedRequirementsByName,
		],
	);

	const updateRequirement: UpdateRequirement =
		useRequirementUpdater(refetchQueries);

	const deleteRequirement = React.useCallback(
		async (id: string): Promise<void> => {
			await deleteRequirementMutation({
				variables: {
					input: {id},
				},
				refetchQueries,
			});
		},
		[deleteRequirementMutation, refetchQueries],
	);
	const shouldShowFaq = true;

	const columns: EntityListColumn[] = useRequirementColumns(shouldShowFaq);

	const {
		formDataResult: {data: formData, loading: formDataLoading},
		renderFormElements,
	} = useRequirementsFormElementsRenderer<RequirementFromListPage>();

	const updateAttachments: UpdateRequirementAttachments =
		useRequirementAttachmentsUpdater();

	const createHrefFromRequirementCol = (
		requirementWithoutType: unknown,
	): string => {
		const requirement = requirementWithoutType as RequirementFromListPage;
		return createHrefFromRequirement(requirement);
	};

	const entityListProps: Partial<EntityListProps> = useMemo(
		() => ({
			onRenderRow: getRenderRowWithContextualMenu(createHrefFromRequirementCol),
		}),
		[],
	);

	return (
		<LoadWrapper loading={loading || formDataLoading}>
			<EntityContextProvider>
				<ParagraphsContextProvider>
					<RequirementsTooltipTranslationProvider>
						<EntityPage
							items={sortBySuccessor(sortedRequirementsByName)}
							entityDisplayName={t('Requirements')}
							entityHeaderText={t('RequirementsHeader')}
							createEntity={createRequirement}
							updateEntity={updateRequirement}
							deleteEntity={deleteRequirement}
							updateEntityAttachments={updateAttachments}
							additionalColumns={columns}
							renderAdditionalFormElements={renderFormElements}
							hideCreate={!isAdmin && !isVex}
							hideEdit={!isAdmin && !isVex}
							hideDelete={!isAdmin && !isVex}
							panelType={PanelType.medium}
							onViewItem={onViewRequirement}
							confirmUpdate
							refetchQueries={[GetAllRequirementsDocument]}
							disableDelete={disableDelete}
							disableEdit={disableEdit}
							entityListProps={entityListProps}
							withNameColumn={false}
							withNameColumnInPanel={true}
						/>
						<NewRequirementVersionPanel formData={formData} />
						<FaqCommand />
					</RequirementsTooltipTranslationProvider>
				</ParagraphsContextProvider>
			</EntityContextProvider>
		</LoadWrapper>
	);
};

export default RequirementsPage;
